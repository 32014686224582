import {
	init as SentryInit,
	globalHandlersIntegration,
	setExtra,
} from '@sentry/browser';
import isObject from 'lodash/isObject';

import {
	APP_CLIENT_VERSION,
	APP_VERSION,
	CLIENT_TYPE,
	SENTRY_ENABLED,
	SENTRY_ENVIRONMENT,
	SENTRY_URL,
} from '~/config';

import {
	ApiException,
} from './model/api/exceptions';



if (SENTRY_ENABLED) {
	SentryInit({
		dsn: SENTRY_URL,
		release: APP_VERSION,
		environment: SENTRY_ENVIRONMENT,
		ignoreErrors: [
			'Failed to fetch',
			'NetworkError when attempting to fetch resource',
			'The user aborted a request.',
			/__show__deepen/,
			// Ignore errors caused by Microsoft crawlers
			// https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
			'Non-Error promise rejection captured',
		],
		integrations: (integrations) => {
			return [
				...integrations,
				globalHandlersIntegration({
					onerror: false,
					onunhandledrejection: true,
				}),
			];
		},
		beforeBreadcrumb: (breadcrumb, hint) => {
			if (breadcrumb.category === 'ui.click') {
				const target = hint?.event?.target;

				if (target.innerText) {
					breadcrumb.message = `${breadcrumb.message} "${hint?.event.target.innerText ?? ''}"`;
				} else {
					const anchor = target.closest('a');

					if (anchor?.href) {
						breadcrumb.message = `${breadcrumb.message} [href=${anchor.href}]`;
					}
				}
			}

			return breadcrumb;
		},
		beforeSend: (event, hint) => {
			try {
				if (hint.originalException instanceof ApiException) {
					return null;
				}

				if (isObject(hint.originalException)) {
					const {
						error,
						details,
					} = (hint.originalException as any);

					// Google login popup was manually closed by user
					if (error === 'popup_closed_by_user') {
						return null;
					}

					// Google login failed because of cookie restrictions in browser
					if (
						error === 'idpiframe_initialization_failed'
						&& details === 'Cookies are not enabled in current environment.'
					) {
						return null;
					}
				}
			} catch (_) {
				// noop
			}

			return event;
		},
	});

	setExtra('APP_CLIENT_VERSION', APP_CLIENT_VERSION);
	setExtra('CLIENT_TYPE', CLIENT_TYPE);
}
